<template>
  <div class="datapicker-container" :class="{'is-invalid': isInvalid}">
    <label class="label-input-text" :class="titleClass">{{ title }}</label>
    <div class="d-flex flex-grow-1">
      <datepicker
              :value="value"
              :input-class="inputClass"
              :wrapper-class="className"
              :format="format"
              :clear-button="true"
              @selected="selected($event)"
              style="
        --padding-x: 15px;
        --radius: 8px;
        --height: 40px;
        --font-size: 14px;
        --line-height: 14px;
        --font-weight: 400px;
      "
              :language="translations"
              :minimum-view="type"
              :placeholder='placeholder'
              class="d-flex"
      ></datepicker>

      <img src="../../assets/icons/calendar.svg"
           v-if="calendarIcon"
           alt="">
    </div>
  </div>
</template>
<script>
  import Datepicker from "vuejs-datepicker";
  import DynamicIcon from "@/components/shared-components/DynamicIcon";

  export default {
    name: "AppDatePicker",
    props: {
      value: Date | String,
      title: String,
      titleClass: {
        type: String,
        default: "",
      },
      inputClass: {
        type: String,
        default: "base-input app-input__field",
      },
      wrapperClass: {
        type: String,
        default: "app-input w-100",
      },
      format: {
        type: String,
        default: "dd.MM.yyyy",
      },
      translations: {
        type: Object,
        default: function () {
          return;
        },
      },
      type: {
        type: String,
        default: "day",
      },
      calendarIcon: {
        type: Boolean,
        default: true,
      },
      isInvalid: {
        type: Boolean,
        default: false
      },
      placeholder:{
        type: String,
        default: ""
      }
    },
    data() {
      return {
        date: null,
      };
    },
    methods: {
      selected(event) {
        this.date = event;
        this.$emit("input", event);
      },
    },
    computed: {
      className() {
        return this.isInvalid ? `${this.wrapperClass} is-invalid` : this.wrapperClass;
      }
    },
    components: {
      Datepicker,
      DynamicIcon,
    },
  };
</script>
<style lang='scss'>
  .w-100 {
    width: 100% !important;
  }

  .datapicker-container {
    .w-100 {
      display: inline-block!important;
    }
  }

  .vdp-datepicker__clear-button {
    position: absolute;
    right: 10px;
    transform: scale(1.2);
  }

  .vdp-datepicker__calendar {
    left: 10px;
  }

  .datapicker-container {
    position: relative;
    // padding: 10px;
    // border: 1px solid #E5EFF5;
    border-radius: 6px;
    display: flex;
    align-items: center;
    max-width: 150px;
  }
  .app-input__field {
    position: absolute;
    left: 0;
    width: 100% !important;
    font-size: 14px;
    height: 18px;
    padding: 0 10px;
  }

  .datapicker-container img {
    position: absolute;
    opacity: 0.5;
    bottom: 14px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .vdp-datepicker.app-input > div:first-child {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
</style>
